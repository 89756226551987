@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .flex-unset {
    flex-direction: unset;
  }
  .controle-tamanho-tela {
    @apply mx-auto overflow-x-hidden md:max-w-4xl 2xl:max-w-7xl;
  }
  input.inputClaro {
    @apply p-4 border border-gray-400 rounded-lg outline-gray-500;
  }
  input.inputClaro:disabled {
    @apply bg-[#f1f1f1];
  }
  .icon-input {
    @apply z-10 items-center justify-center w-5 h-5 -mt-2 -ml-5 text-opacity-25 text-secondary-tim;
  }
  .btn {
    @apply w-full py-4 text-sm font-extrabold uppercase rounded-full;
  }
  .w-3\/3 {
    @apply w-full;
  }

  .animate-bounce-lento {
    animation: bounce-lento 3s 5;
    animation-delay: 30s;
  }
  #iframe-digipay iframe {
    @apply h-full;
  }
  #iframe-digipay iframe * {
    @apply h-full;
  }
  @keyframes bounce-lento {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(25%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
@layer utilities {
  .transition-height {
    transition: max-height 0.5s;
  }

  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #006faa;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #434343;
  }
}

@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: normal;
  /*woff2 FF39+,Chrome36+, Opera24+*/
  /*woff FF3.6+, IE9, Chrome6+, Saf5.1+*/
  src: url("./fonts/TIMSans/TIMSansWeb-Regular.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Regular.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Regular.woff") format("woff");
}
@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/TIMSans/TIMSansWeb-Thin.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Thin.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Thin.woff") format("woff");
}
@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/TIMSans/TIMSansWeb-Light.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Light.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Light.woff") format("woff");
}
@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/TIMSans/TIMSansWeb-Medium.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Medium.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/TIMSans/TIMSansWeb-Bold.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Bold.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: "TIMSans";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/TIMSans/TIMSansWeb-Heavy.eot");
  src: url("./fonts/TIMSans/TIMSansWeb-Heavy.woff2") format("woff2"), url("./fonts/TIMSans/TIMSansWeb-Heavy.woff2") format("woff");
}
